import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import { IconContext } from "react-icons"
import { BiBuildingHouse } from "react-icons/bi"
import { BsBuilding } from "react-icons/bs"
import { GiFactory } from "react-icons/gi"
import { Element, Link as ScrollLink } from "react-scroll"
import styled, { css } from "styled-components"

import { media } from "../styles/media"
import fsscImg from "../../src/images/fssc.jpg"

import Layout from "../components/Layout"
import HeaderMedia from "../components/PageHeaderMedia"
import SEO from "../components/SEO"

const IconFactory = () => (
  <IconContext.Provider value={{ color: "#008742", size: "1.32em" }}>
    <GiFactory />
  </IconContext.Provider>
)

const IconOffice = () => (
  <IconContext.Provider value={{ color: "#E50012", size: "1.32em" }}>
    <BsBuilding />
  </IconContext.Provider>
)

const IconBranch = () => (
  <IconContext.Provider value={{ color: "gray", size: "1.32em" }}>
    <BiBuildingHouse />
  </IconContext.Provider>
)

const selectIcon = icon => {
  if (icon === "1") return IconOffice()
  if (icon === "2") return IconFactory()
  return IconBranch()
}

const StyledAnchors = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-around;
  padding: 50px 20%;
  a {
    display: block;
    width: 14%;
    margin: 0 3%;
  }
  ${() =>
    media.sp(css`
      padding: 50px 5% 30px;
      justify-content: space-between;
      a {
        width: 15%;
      }
    `)}
`

const StyledLists = styled.div`
  padding: 50px 5% 100px;
  .corpSection {
    margin-bottom: 100px;
    h2 {
      font-size: 1.32em;
      display: flex;
      flex-flow: column nowrap;
      span {
        display: block;
        content: "";
        width: 100%;
        height: 3px;
        border-radius: 10px;
        background: #dadada;
        margin: 5px 0 30px;
        &:after {
          display: block;
          content: "";
          width: 12.6%;
          height: 3px;
          border-radius: 10px;
          background: grey;
        }
      }
    }
  }
  .corpLine {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
    width: 100%;
    h3 {
      width: 100%;
      padding: 22px 0 11px;
      font-size: 14px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      color: #777;
      &:after {
        content: "";
        display: block;
        width: 44px;
        height: 2px;
        border-radius: 2px;
        background: #aaa;
        margin-left: 11px;
      }
    }
    h4 {
      width: 20%;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      svg {
        margin-right: 0.62rem;
      }
    }
    .zip {
      width: 10%;
      font-size: 0.8em;
    }
    .address {
      width: 35%;
      font-size: 0.9em;
      position: relative;
    }
    .phone {
      width: 15%;
      font-size: 0.9em;
    }
    a,
    span {
      width: 15%;
      text-align: center;
      background: #acacac;
      color: white;
      padding: 10px 0;
      font-size: 11px;
      transition: 0.4s;
      border-radius: 5px;
      &:hover {
        background: black;
      }
    }
  }
  ${() =>
    media.tablet(css`
      .corpLine {
        h4 {
          width: 30%;
          font-size: 0.9em;
          font-weight: bold;
        }
        .zip {
          display: none;
        }
        .phone {
          display: none;
        }
        .address {
          width: 50%;
        }
      }
    `)}
  ${() =>
    media.sp(css`
      .corpLine {
        h4 {
          width: 50%;
        }
        .address {
          width: 70%;
          order: 3;
        }
        a {
          width: 25%;
          order: 4;
        }
      }
    `)}
`

const scrollAtt = {
  activeClass: "active",
  spy: true,
  smooth: true,
  offset: -80,
  duration: 500,
}

const titleData = [
  { id: 5, name: "広域量販支店" },
  { id: 7, name: "福岡支店" },
  { id: 8, name: "西九州支店" },
  { id: 9, name: "中九州支店" },
  { id: 11, name: "南九州支店" },
]

const addStyle = {
  background: "none",
  color: "black",
  fontSize: 0.5,
  position: "absolute",
  top: "50%",
  left: "93%",
  transform: "translate(-50%,-50%)",
  width: 99,
  display: "flex",
  flexFlow: "column nowrap",
  alignItems: "center",
}
const addimgStyle = {
  width: 22,
}

const CompanyLocations = () => {
  const data = useStaticQuery(query)
  const { strapiLocationList: locationData } = data
  return (
    <Layout>
      <SEO>
        <title>拠点一覧 | 株式会社フランソア</title>
        <meta
          name="description"
          content="株式会社フランソアグループの拠点住所等についてのページ。"
        />
      </SEO>
      <HeaderMedia
        fluid={locationData.pageHeaderMedia.childImageSharp.fluid}
        pageTitles={locationData.pageHeaderText}
      />

      <StyledAnchors>
        {locationData.logoList.logoAndText.map((loc, index) => (
          <ScrollLink {...scrollAtt} to={loc.anchorUrl} key={index}>
            <span key={loc.id}>
              <Image fluid={loc.anchorLogo.childImageSharp.fluid} />
            </span>
          </ScrollLink>
        ))}
      </StyledAnchors>

      <StyledLists>
        {locationData.locationSection.map((loc, i) => (
          <div className="corpSection" key={loc.id}>
            <Element name={loc.anchorLink} />
            <h2>
              {loc.sectionTitle}
              <span></span>
            </h2>
            {loc.locationLIstOne.map((data, index) => (
              <div className="corpLine" key={index}>
                {i === 0
                  ? titleData.find(w => w.id === index) && (
                      <h3>{titleData.find(w => w.id === index).name} </h3>
                    )
                  : null}
                <h4>
                  {selectIcon(data.iconDesignName)}
                  {data.iconName}
                </h4>
                <p className="zip">{data.zipCode}</p>
                <p className="address">
                  {data.addressName}
                  {data.fsscMark && (
                    <span style={addStyle}>
                      <img src={fsscImg} alt="fssc" style={addimgStyle} />
                      {data.fsscMark}
                    </span>
                  )}
                </p>
                <p className="phone">{data.phoneNum}</p>
                {data.googleMapUrl ? (
                  <a
                    href={data.googleMapUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    地図を見る
                  </a>
                ) : (
                  <span href="/" style={{ opacity: "0" }}>
                    地図無
                  </span>
                )}
              </div>
            ))}
          </div>
        ))}
      </StyledLists>
    </Layout>
  )
}

export const query = graphql`
  {
    strapiLocationList {
      pageHeaderMedia {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      pageHeaderText
      logoList {
        id
        logoAndText {
          anchorUrl
          anchorLogo {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      locationSection {
        id
        sectionTitle
        anchorLink
        locationLIstOne {
          addressName
          googleMapUrl
          iconDesignName
          fsscMark
          iconName
          phoneNum
          zipCode
        }
      }
    }
  }
`

export default CompanyLocations
